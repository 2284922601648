// Generated by Framer (2923eb5)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Form from "./ceTqd348J";
const FormFonts = getFonts(Form);

const serializationHash = "framer-IvFBa"

const variantClassNames = {byJr80Bx2: "framer-v-gd8cb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "byJr80Bx2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-gd8cb", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"byJr80Bx2"} ref={ref ?? ref1} style={{background: "linear-gradient(89.97344611927724deg, rgba(166, 192, 254, 1) 0%, rgba(255, 234, 246, 1) 100%)", ...style}}><ComponentViewportProvider height={981} y={((componentViewport?.y || 0) + (40 + ((((componentViewport?.height || 595) - 80) - 981) / 2)))}><motion.div className={"framer-1v4j92a-container"} layoutDependency={layoutDependency} layoutId={"jUQZCMUCS-container"}><Form height={"100%"} id={"jUQZCMUCS"} layoutId={"jUQZCMUCS"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IvFBa.framer-1k1s4dm, .framer-IvFBa .framer-1k1s4dm { display: block; }", ".framer-IvFBa.framer-gd8cb { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; min-height: calc(var(--framer-viewport-height, 100vh) * 1); overflow: hidden; padding: 40px; position: relative; width: 1200px; }", ".framer-IvFBa .framer-1v4j92a-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IvFBa.framer-gd8cb { gap: 0px; } .framer-IvFBa.framer-gd8cb > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-IvFBa.framer-gd8cb > :first-child { margin-left: 0px; } .framer-IvFBa.framer-gd8cb > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 595
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"],"constraints":[null,null,"100vh",null]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGfpz5J2rI: React.ComponentType<Props> = withCSS(Component, css, "framer-IvFBa") as typeof Component;
export default FramerGfpz5J2rI;

FramerGfpz5J2rI.displayName = "Info Form";

FramerGfpz5J2rI.defaultProps = {height: 595, width: 1200};

addFonts(FramerGfpz5J2rI, [{explicitInter: true, fonts: []}, ...FormFonts], {supportsExplicitInterCodegen: true})